import React, { useEffect, useState } from "react";
import Info from "../../components/Info";
import Soufflet from "../../components/Soufflet";
import CopyButton from "../../components/CopyButton";

interface GBIFData {
  key: number;
  nameKey: number;
  datasetKey: string;
  nubKey: number;
  parentKey: number;
  parent: string;
  kingdom: string;
  phylum: string;
  order: string;
  family: string;
  genus: string;
  species: string;
  kingdomKey: number;
  phylumKey: number;
  classKey: number;
  orderKey: number;
  familyKey: number;
  genusKey: number;
  speciesKey: number;
  scientificName: string;
  canonicalName: string;
  authorship: string;
  nameType: string;
  taxonomicStatus: string;
  rank: string;
  origin: string;
  numDescendants: number;
  numOccurrences: number;
  taxonID: string;
  habitats: string[];
  nomenclaturalStatus: string[];
  threatStatuses: string[];
  descriptions: string[];
  vernacularNames: {
    vernacularName: string;
    language: string;
  }[];
  higherClassificationMap: {
    [key: string]: string;
  };
  synonym: boolean;
  class: string;
}

function safeArray(array: any[]) {
  if (!array) return [];
  return array;
}

function ColoredRank({ rank }: { rank: string }) {
  return (
    <span style={{ color: rank === "SPECIES" ? "green" : "orange" }}>
      {rank}
    </span>
  );
}

function VernacularNames({
  vernacularNames,
}: {
  vernacularNames: { vernacularName: string; language: string }[];
}) {
  function HowManyFrench() {
    return vernacularNames.filter((name) => isFrench(name.language)).length;
  }

  function getOnlyFrench() {
    return vernacularNames.filter((name) => isFrench(name.language));
  }
  function getOnlyFrenchString() {
    const frenchNames = getOnlyFrench();
    if (frenchNames.length === 0) {
      return "";
    }
    return frenchNames.map((name) => name.vernacularName).join(", ");
  }

  function isFrench(language: string) {
    return language === "fra";
  }

  function orderByLanguage(
    vernacularNames: {
      vernacularName: string;
      language: string;
    }[]
  ) {
    return vernacularNames.sort((a, b) => {
      if (!a.language || !b.language) return 0;
      if (a.language === "fra") return -1;
      if (b.language === "fra") return 1;
      if (a.language === "eng") return -1;
      if (b.language === "eng") return 1;
      return a.language.localeCompare(b.language);
    });
  }

  const count = vernacularNames.length;
  const countFrench = HowManyFrench();
  let countFrenchString = `(${countFrench} in ${"🇫🇷"})`;
  if (countFrench === 0) {
    countFrenchString = "";
  }
  return (
    <>
      <Soufflet
        title={`${count} vernacular names ${countFrenchString}(${getOnlyFrenchString()})`}
      >
        {orderByLanguage(vernacularNames).map((name) => (
          <>
            {name.vernacularName} ({name.language})
            {isFrench(name.language) && (
              <span style={{ color: "red" }}>🇫🇷</span>
            )}
            <br />
          </>
        ))}
      </Soufflet>
    </>
  );
}

function Description({ descriptions }: { descriptions: string[] }) {
  return (
    <Soufflet title={`${descriptions.length} descriptions`}>
      {descriptions.map((description) => (
        <>
          <p>{description.description}</p>
          <hr />
        </>
      ))}
    </Soufflet>
  );
}

function LinkWithCopyButton({ url, text }: { url: string; text: string }) {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: "2px" }}>
      <a href={url} target="_blank">
        {text}
      </a>
      &nbsp;
      <CopyButton val={text} />
    </div>
  );
}

function GBIFDataDisplay({
  gbifData,
  allowSelectSpecie = false,
  selectSpecieCallback = () => {},
}: {
  gbifData: GBIFData;
  allowSelectSpecie: boolean;
  selectSpecieCallback: (gbifData: GBIFData) => void;
}) {
  function isSpecies() {
    return gbifData.rank === "SPECIES";
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "2px",
          marginBottom: "20px",
        }}
      >
        <div style={{ width: "100px" }}></div>
        <h2 style={{ textAlign: "center", margin: "0" }}>
          {gbifData.scientificName}
          &nbsp;
          <CopyButton val={gbifData.scientificName} />
        </h2>
        <div style={{ width: "100px" }}>
          <ColoredRank rank={gbifData.rank} />
        </div>
        {allowSelectSpecie && isSpecies() && (
          <div style={{ width: "50px" }}>
            <button
              style={{
                padding: "5px 10px",
                fontSize: "16px",
                backgroundColor: "#4CAF50",
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                marginTop: "30px",
              }}
              onClick={() => selectSpecieCallback(gbifData)}
            >
              Select
            </button>
          </div>
        )}
      </div>
      <div>
        <table className="admin-table">
          <tbody>
            <tr>
              <td>Kingdom</td>
              <td>Phylum</td>
              <td>Class</td>
              <td>Order</td>
              <td>Family</td>
              <td>Genus</td>
              <td>Species</td>
              <td>SpeciesKey</td>
            </tr>
            <tr>
              <td>
                <LinkWithCopyButton
                  url={`https://www.gbif.org/species/${gbifData.kingdomKey}`}
                  text={gbifData.kingdom}
                />
              </td>
              <td>
                <LinkWithCopyButton
                  url={`https://www.gbif.org/species/${gbifData.phylumKey}`}
                  text={gbifData.phylum}
                />
              </td>
              <td>
                <LinkWithCopyButton
                  url={`https://www.gbif.org/species/${gbifData.classKey}`}
                  text={gbifData.class}
                />
              </td>
              <td>
                <LinkWithCopyButton
                  url={`https://www.gbif.org/species/${gbifData.orderKey}`}
                  text={gbifData.order}
                />
              </td>
              <td>
                <LinkWithCopyButton
                  url={`https://www.gbif.org/species/${gbifData.familyKey}`}
                  text={gbifData.family}
                />
              </td>
              <td>
                <LinkWithCopyButton
                  url={`https://www.gbif.org/species/${gbifData.genusKey}`}
                  text={gbifData.genus}
                />
              </td>
              <td>
                <LinkWithCopyButton
                  url={`https://www.gbif.org/species/${gbifData.speciesKey}`}
                  text={gbifData.species}
                />
              </td>
              <td>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "2px" }}
                >
                  {gbifData.speciesKey}
                  &nbsp;
                  <CopyButton val={gbifData.speciesKey} />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style={{ marginTop: "10px" }}>
        <VernacularNames vernacularNames={gbifData.vernacularNames} />
      </div>
      <Description descriptions={gbifData.descriptions} />
      {safeArray(gbifData.habitats).length > 0 && (
        <div>
          <span style={{ fontWeight: "bold" }}>Habitats:</span>
          <br />
          <p>{gbifData.habitats.join(", ")}</p>
        </div>
      )}
      {safeArray(gbifData.threatStatuses).length > 0 && (
        <div>
          <span style={{ fontWeight: "bold" }}>Conservation Status:</span>
          <br />
          <p>{gbifData.threatStatuses.join(", ")}</p>
        </div>
      )}
    </div>
  );
}

function GBIFDataList({
  gbifDataList,
  totalResults,
  allowSelectSpecie = false,
  selectSpecieCallback = () => {},
}: {
  gbifDataList: GBIFData[];
  totalResults: number;
  allowSelectSpecie: boolean;
  selectSpecieCallback: (gbifData: GBIFData) => void;
}) {
  function Results({
    count,
    totalResults,
  }: {
    count: number;
    totalResults: number;
  }) {
    const maxResults = 50;
    if (count === 0) {
      return (
        <div
          style={{
            width: "80%",
            margin: "20px auto",
            padding: "20px",
            borderRadius: "10px",
            boxShadow: "5px 1px 20px rgba(0, 0, 0, 0.3)",
          }}
        >
          No results
        </div>
      );
    }
    if (count === maxResults) {
      return (
        <div
          style={{
            color: "red",
            display: "flex",
            alignItems: "center",
            gap: "8px",
            width: "80%",
            margin: "0 auto",
          }}
        >
          {totalResults} results
          <Info
            text={`L'API ne retourne que ${maxResults} résultats maximum,\ntous les résultats ne sont donc pas affichés.\nAffiner la recherche.`}
          />
        </div>
      );
    }
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          width: "80%",
          margin: "0 auto",
        }}
      >
        <span style={{ fontWeight: "bold" }}>{count} </span>results
      </div>
    );
  }
  return (
    <>
      <Results count={gbifDataList.length} totalResults={totalResults} />
      <br />
      <div>
        {gbifDataList.map((gbifData) => (
          <React.Fragment key={gbifData.key}>
            <div
              style={{
                width: "80%",
                margin: "20px auto",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "5px 1px 20px rgba(0, 0, 0, 0.3)",
              }}
              key={gbifData.key}
            >
              <GBIFDataDisplay
                gbifData={gbifData}
                allowSelectSpecie={allowSelectSpecie}
                selectSpecieCallback={selectSpecieCallback}
              />
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  );
}

const GBIFexplorerComponent = ({
  allowSelectSpecie = false,
  selectSpecieCallback = () => {},
  initialSearchTerm = "",
}: {
  allowSelectSpecie: boolean;
  selectSpecieCallback: (gbifData: GBIFData) => void;
  initialSearchTerm: string;
}) => {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [result, setResult] = useState<GBIFData[]>([]);
  const [onlySpecies, setOnlySpecies] = useState(true);
  const [onlyHabitatTerrestrial, setOnlyHabitatTerrestrial] = useState(false);
  const [onlyAnimalia, setOnlyAnimalia] = useState(true);
  const [onlyChordata, setOnlyChordata] = useState(true);
  const [totalResults, setTotalResults] = useState(0);

  useEffect(() => {
    if (onlyChordata) {
      setOnlyAnimalia(true);
    }
  }, [onlyChordata]);

  useEffect(() => {
    if (!onlyAnimalia) {
      setOnlyChordata(false);
    }
  }, [onlyAnimalia]);

  function getGBIFData(
    searchTerm: string,
    onlySpecies = true,
    onlyHabitatTerrestrial = true,
    onlyAnimalia = true,
    onlyChordata = true
  ) {
    const datasetKeyINPN = "0e61f8fe-7d25-4f81-ada7-d970bbb2c6d6";
    const datasetKeyGBIF = "d7dddbf4-2cf0-4f39-9b2a-bb099caae36c";
    const useDatasetKey = true;
    let url = `https://api.gbif.org/v1/species/search?q=${searchTerm}&limit=50`;
    if (useDatasetKey) {
      url += `&datasetKey=${datasetKeyGBIF}`;
    }
    if (onlySpecies) {
      url += `&rank=SPECIES`;
    }
    url += `&status=ACCEPTED`;

    if (onlyHabitatTerrestrial) {
      url += `&habitat=terrestrial`;
    }
    let higherTaxonKey = "";
    if (onlyAnimalia) {
      higherTaxonKey = `&higherTaxonKey=1`;
    }
    if (onlyChordata) {
      higherTaxonKey = `&higherTaxonKey=44`;
    }
    if (higherTaxonKey !== "") {
      url += higherTaxonKey;
    }
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setResult(data.results);
        setTotalResults(data.count);
      });
  }

  useEffect(() => {
    getGBIFData(
      searchTerm,
      onlySpecies,
      onlyHabitatTerrestrial,
      onlyAnimalia,
      onlyChordata
    );
  }, [
    searchTerm,
    onlySpecies,
    onlyHabitatTerrestrial,
    onlyAnimalia,
    onlyChordata,
  ]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          width: "80%",
          margin: "0px auto",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "5px 1px 20px rgba(0, 0, 0, 0.3)",
        }}
      >
        <span style={{ fontWeight: "bold" }}>Search GBIF:</span>
        <input
          type="text"
          placeholder="Search GBIF"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1px",
            cursor: "pointer",
            border: "1px solid #ccc",
            padding: "2px",
          }}
        >
          <input
            type="checkbox"
            id="onlySpecies"
            checked={onlySpecies}
            onChange={(e) => setOnlySpecies(e.target.checked)}
            title="Only species"
          />
          <label htmlFor="onlySpecies" style={{ cursor: "pointer" }}>
            Only species
          </label>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0px",
            border: "1px solid #ccc",
            padding: "2px",
          }}
        >
          <input
            type="checkbox"
            id="onlyHabitatTerrestrial"
            checked={onlyHabitatTerrestrial}
            onChange={(e) => setOnlyHabitatTerrestrial(e.target.checked)}
            title="Only terrestrial"
          />
          <label htmlFor="onlyHabitatTerrestrial" style={{ cursor: "pointer" }}>
            Only terrestrial
          </label>
          <Info
            text={`Attention filtre non fiable:\nCertaines espèces n'ont pas ce champ 'habitat' renseigné.\nActiver ce filtre peut servir a limiter les résultats`}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0px",
            border: "1px solid #ccc",
            padding: "2px",
          }}
        >
          <input
            type="checkbox"
            id="onlyAnimalia"
            checked={onlyAnimalia}
            onChange={(e) => setOnlyAnimalia(e.target.checked)}
            title="Only Animalia"
          />
          <label htmlFor="onlyAnimalia" style={{ cursor: "pointer" }}>
            Only Animalia (Kingdom)
          </label>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0px",
            border: "1px solid #ccc",
            padding: "2px",
          }}
        >
          <input
            type="checkbox"
            id="onlyChordata"
            checked={onlyChordata}
            onChange={(e) => setOnlyChordata(e.target.checked)}
            title="Only Chordata"
          />
          <label htmlFor="onlyChordata" style={{ cursor: "pointer" }}>
            Only Chordata (Phylum)
          </label>
        </div>
      </div>
      {result && (
        <GBIFDataList
          gbifDataList={result}
          totalResults={totalResults}
          allowSelectSpecie={allowSelectSpecie}
          selectSpecieCallback={selectSpecieCallback}
        />
      )}
    </div>
  );
};

export { GBIFexplorerComponent, GBIFData };
