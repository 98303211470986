import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useAdmin from "../hooks/useAdmin";
import useSuperadmin from "../hooks/useSuperadmin";
import { getClientName } from "../ConfigProvider";
import OnlyNico from "../components/OnlyNico";

function AdminHome() {
  const { canBeAdmin } = useAdmin();
  const isSuperadmin = useSuperadmin();

  const [clientName, setClientName] = useState(null);

  useEffect(() => {
    getClientName().then(setClientName);
  }, []);

  if (!canBeAdmin) {
    return (
      <div className="mainpage">
        <p>Vous n'êtes pas autorisé à accéder à cette page.</p>
      </div>
    );
  }

  return (
    <div className="mainpage">
      <h2 style={{ textAlign: "center", padding: "10px" }}>Administration:</h2>

      <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        <Link
          to="/admin/inventaireCapteurs"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <div className="admin-card">
            <i
              className="fa-solid fa-list"
              style={{ fontSize: "2em", marginBottom: "10px" }}
            ></i>
            <span>Inventaire des capteurs</span>
          </div>
        </Link>
      </div>
      <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        <Link
          to="/admin/etatCapteurs"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <div className="admin-card">
            <i
              className="fa-solid fa-stopwatch"
              style={{ fontSize: "2em", marginBottom: "10px" }}
            ></i>
            <span>Delai de collecte</span>
          </div>
        </Link>

        <Link
          to="/admin/prochainesCampagnes"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <div className="admin-card">
            <i
              className="fa-solid fa-calendar-days"
              style={{ fontSize: "2em", marginBottom: "10px" }}
            ></i>
            <span>Prochaines campagnes</span>
          </div>
        </Link>

        <Link
          to="/admin/campagnes"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <div className="admin-card">
            <i
              className="fa-solid fa-clipboard-list"
              style={{ fontSize: "2em", marginBottom: "10px" }}
            ></i>
            <span>
              Suivi des campagnes
              <br />
              <small>(passées)</small>
            </span>
          </div>
        </Link>
      </div>

      <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        <Link
          to="/admin/taxref/distinctTaxref"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <div className="admin-card">
            <i
              className="fa-solid fa-paw"
              style={{ fontSize: "2em", marginBottom: "10px" }}
            ></i>
            <span>
              Taxref distincts
              <br />
              <small>(tous les clients)</small>
            </span>
          </div>
        </Link>

        <Link
          to="/admin/taxref/GBIFexplorerPage"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <div className="admin-card">
            <i
              className="fa-solid fa-globe"
              style={{ fontSize: "2em", marginBottom: "10px" }}
            ></i>
            <span>GBIF Explorer</span>
          </div>
        </Link>

        <OnlyNico>
          <Link
            to="/admin/taxref/taxrefGBIF"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className="admin-card">
              <i
                className="fa-solid fa-database"
                style={{ fontSize: "2em", marginBottom: "10px" }}
              ></i>
              <span>Taxref GBIF</span>
            </div>
          </Link>
        </OnlyNico>
      </div>
      {isSuperadmin && (
        <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
          <Link
            to="/datasets"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className="admin-card">
              <i
                className="fa-solid fa-database"
                style={{ fontSize: "2em", marginBottom: "10px" }}
              ></i>
              <span>Datasets</span>
            </div>
          </Link>
        </div>
      )}
      <hr style={{ width: "70%" }} />

      <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
        <Link
          to="/admin/siteConfig"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <div className="admin-card">
            <i
              className="fa-solid fa-braille"
              style={{ fontSize: "2em", marginBottom: "10px" }}
            ></i>
            <span>Configuration du site {clientName}</span>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default AdminHome;
