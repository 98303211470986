import React, { useState, useEffect } from "react";
import { getUserName } from "../ConfigProvider";

const OnlyNico = ({ children }) => {
  const [isNico, setIsNico] = useState(false);

  useEffect(() => {
    getUserName().then((userName) => {
      setIsNico(userName.startsWith("e1c_nico"));
    });
  }, []);

  if (!isNico) {
    return <></>;
  }

  return React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        style: { ...child.props.style, backgroundColor: "orange" },
      });
    }
    return child; // For plain text or non-element children, return as is.
  });
};

export default OnlyNico;
